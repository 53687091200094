import styles from "components/cards/myInfoCard.module.scss";
import utilStyles from "styles/utils.module.scss";

import ArrowRightIcon from "images/icons/arrow_right_black.svg";
import ArrowRightLargeIcon from "images/icons/arrow_right_black_16.svg";
import SilverIcon from "images/icons/silver.svg";
import Clipboard from "react-clipboard.js";
import {useContext, useEffect, useRef, useState} from "react";
import UserContext from "context/AuthContext";
import {numberWithComma, useOutsideAlerter} from "common/utils";
import Link from "next/link";
import {
    couponsRoute,
    getCreatorRoute,
    KAKAO_CLIENT_ID,
    myInfoRoute,
    myPageRoute,
    pointsRoute, reviewRoute,
    SITE_URL,
    storeRoute
} from "common/const";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import Image from "next/image";
import DefaultProfileIcon from "images/icons/default_profile.svg";
import FullButton from "components/buttons/FullButton";

import ShareIcon from "images/icons/share_link_noborder.svg";
import {toast} from "react-toastify";
import {SelfVerificationButton} from "pages/mypage/my-info";
import {useRouter} from "next/router";
import Modal from "components/Modal";
import TitleBar from "components/layout/TitleBar";
import {useFormik} from "formik";
import {
    KEY_NICKNAME,
    KEY_NICKNAME_CONTAINS_ALPHABET,
    KEY_NICKNAME_EXISTS, KEY_NICKNAME_LEGAL, NICKNAME_ERROR_FIELDS,
    REGEX_CONTAINS_ALPHABET, REGEX_NICKNAME_LEGAL
} from "pages/auth/sign-up";
import * as yup from "yup";
import {Axios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";
import TextInput from "components/input/TextInput";
import CheckedInputError from "components/input/CheckedInputError";

import InstagramIcon from "images/icons/insta_link_black.svg";
import YoutubeIcon from "images/icons/youtube_link_black.svg";
import TiktokIcon from "images/icons/tiktok_link_black.svg";
import NaverBlogIcon from "images/icons/naver_blog_link_black.svg";
import KakaoIcon from "images/icons/kakao.svg";
import * as Sentry from "@sentry/nextjs";
import CopyIcon from "images/icons/copy.svg";
import CloseIcon from "images/icons/close.svg";
import Script from "next/script";
import SpeechBubble from "components/SpeechBubble";

import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';


const ProfileImage = ({user}) => {
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    return (
        <>
            {
                user.profile_image_url
                    ?
                    <div className={classNames(styles.profileImage, styles.profileImageBorder)}>
                        <Image unoptimized src={user.profile_image_url} width={isLarge ? 80: 72} height={isLarge ? 80: 72} objectFit="fill" />
                    </div>
                    : <DefaultProfileIcon className={styles.profileImage} viewBox="0 0 32 32" />
            }
        </>
    )
}



export const KEY_INTRODUCTION = 'introduction';
export const KEY_INSTAGRAM_LINK = 'instagram_link';
export const KEY_YOUTUBE_LINK = 'youtube_link';
export const KEY_TIKTOK_LINK = 'tiktok_link';
export const KEY_NAVER_BLOG_LINK = 'naver_blog_link';

const KEY_CREATOR_CODE = 'creator_code';
const KEY_CREATOR_CODE_EXISTS = 'creator_code_exists';
const KEY_CREATOR_CODE_CONTAINS_ALPHABET = 'creator_code_contains_alphabet';
const KEY_CREATOR_CODE_LEGAL = 'creator_code_legal';
const REGEX_CREATOR_CODE_LEGAL = REGEX_NICKNAME_LEGAL;
const CREATOR_ERROR_FIELDS = [KEY_CREATOR_CODE_CONTAINS_ALPHABET, KEY_CREATOR_CODE_LEGAL, KEY_CREATOR_CODE];
export const SellerShopInfoForm = ({formik, isLoading}) => {
    const { t } = useTranslation('components-cards-MyInfoCard');
    const {errors, values, setFieldValue, touched, setFieldTouched, resetForm, handleSubmit, setTouched, setFieldError} = formik;

    return (
        <>
            <div className={styles.titleRow}>
                <span className={styles.titleRowTitle}>{t('SellerShopInfoForm.introduction.title')}</span>
                <span className={styles.titleRowSubTitle}>{t('SellerShopInfoForm.introduction.optional')}</span>
                <div style={{marginTop: 12}}>
                    <TextInput
                        readonly={isLoading}
                        value={values[KEY_INTRODUCTION]}
                        onChange={(e) => setFieldValue(KEY_INTRODUCTION, e)}
                        maxLength={150}
                        multiLine
                        height={160}
                        placeholder={t('SellerShopInfoForm.introduction.placeholder')}
                    />
                </div>
            </div>
            <div className={styles.titleRow}>
                <span className={styles.titleRowTitle}>{t('SellerShopInfoForm.links.title')}</span>
                <span className={styles.titleRowSubTitle}>{t('SellerShopInfoForm.links.optional')}</span>
            </div>
            <div>
                <div className={styles.linkRow}>
                    <InstagramIcon viewBox="0 0 40 40" className={styles.linkIcon}  />
                    <div className={styles.linkInputWrapper}>
                        <TextInput
                            readonly={isLoading}
                            value={values[KEY_INSTAGRAM_LINK]}
                            onChange={(e) => setFieldValue(KEY_INSTAGRAM_LINK, e)}
                            placeholder={t('SellerShopInfoForm.links.placeholder')}
                        />
                    </div>
                </div>
                <div className={styles.linkRow}>
                    <YoutubeIcon viewBox="0 0 40 40" className={styles.linkIcon}  />
                    <div className={styles.linkInputWrapper}>
                        <TextInput
                            readonly={isLoading}
                            value={values[KEY_YOUTUBE_LINK]}
                            onChange={(e) => setFieldValue(KEY_YOUTUBE_LINK, e)}
                            placeholder={t('SellerShopInfoForm.links.placeholder')}
                        />
                    </div>
                </div>
                <div className={styles.linkRow}>
                    <TiktokIcon viewBox="0 0 40 40" className={styles.linkIcon}  />
                    <div className={styles.linkInputWrapper}>
                        <TextInput
                            readonly={isLoading}
                            value={values[KEY_TIKTOK_LINK]}
                            onChange={(e) => setFieldValue(KEY_TIKTOK_LINK, e)}
                            placeholder={t('SellerShopInfoForm.links.placeholder')}
                        />
                    </div>
                </div>
                <div className={styles.linkRow}>
                    <NaverBlogIcon viewBox="0 0 40 40" className={styles.linkIcon}  />
                    <div className={styles.linkInputWrapper}>
                        <TextInput
                            readonly={isLoading}
                            value={values[KEY_NAVER_BLOG_LINK]}
                            onChange={(e) => setFieldValue(KEY_NAVER_BLOG_LINK, e)}
                            placeholder={t('SellerShopInfoForm.links.placeholder')}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

SellerShopInfoForm.initialValues = {
    [KEY_INTRODUCTION]: '',
    [KEY_INSTAGRAM_LINK]: '',
    [KEY_YOUTUBE_LINK]: '',
    [KEY_TIKTOK_LINK]: '',
    [KEY_NAVER_BLOG_LINK]: '',
}

SellerShopInfoForm.validationSchema = {
    [KEY_INTRODUCTION]: yup.string().max(150),
    [KEY_INSTAGRAM_LINK]: yup.string(),
    [KEY_YOUTUBE_LINK]: yup.string(),
    [KEY_TIKTOK_LINK]: yup.string(),
    [KEY_NAVER_BLOG_LINK]: yup.string(),
    [KEY_CREATOR_CODE_LEGAL]: yup.boolean().when(KEY_CREATOR_CODE, (code, schema) => {
        if (!code) return schema;
        if (!code.match(REGEX_CREATOR_CODE_LEGAL)) {
            return schema.oneOf([true]).required();
        }
    }),
}

SellerShopInfoForm.preSubmit = (values, callback) => {
    if (values[KEY_INSTAGRAM_LINK] && !values[KEY_INSTAGRAM_LINK].trim().startsWith('https://') && !values[KEY_INSTAGRAM_LINK].trim().startsWith('http://')) {
        alert("인스타그램 링크는 http:// 또는 https://로 시작해야 합니다.");
        return false;
    }

    if (values[KEY_YOUTUBE_LINK] && !values[KEY_YOUTUBE_LINK].trim().startsWith('https://') && !values[KEY_YOUTUBE_LINK].trim().startsWith('http://')) {
        alert("유튜브 링크는 http:// 또는 https://로 시작해야 합니다.");
        return false;
    }

    if (values[KEY_TIKTOK_LINK] && !values[KEY_TIKTOK_LINK].trim().startsWith('https://') && !values[KEY_TIKTOK_LINK].trim().startsWith('http://')) {
        alert("틱톡 링크는 http:// 또는 https://로 시작해야 합니다.");
        return false;
    }

    if (values[KEY_NAVER_BLOG_LINK] && !values[KEY_NAVER_BLOG_LINK].trim().startsWith('https://') && !values[KEY_NAVER_BLOG_LINK].trim().startsWith('http://')) {
        alert("네이버 블로그 링크는 http:// 또는 https://로 시작해야 합니다.");
        return false;
    }

    return true;
}


const SellerShopCreateModal = ({close}) => {
    const { t } = useTranslation('components-cards-MyInfoCard');
    const [isLoading, setIsLoading] = useState(false);

    const userContext = useContext(UserContext);

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: Object.assign(
            {},
            {
                [KEY_CREATOR_CODE]: '',
                [KEY_CREATOR_CODE_EXISTS]: '',
            },
            SellerShopInfoForm.initialValues,
        ),
        validationSchema: yup.object(
            Object.assign(
                {},
                {
                    [KEY_CREATOR_CODE]: yup.string().required().min(2).max(20),
                    [KEY_CREATOR_CODE_CONTAINS_ALPHABET]: yup.boolean().when(KEY_CREATOR_CODE, (code, schema) => {
                        if (!code) return schema.required();
                        if (!code.match(REGEX_CONTAINS_ALPHABET)) {
                            return schema.oneOf([true]).required();
                        }
                    }),
                },
                SellerShopInfoForm.validationSchema,
            )
        ),
        onSubmit: async values => {
            if (values[KEY_CREATOR_CODE_EXISTS]) {
                alert(t('SellerShopCreateModal.validationMessages.alreadyInUse'));
                return;
            }

            if (!SellerShopInfoForm.preSubmit(values)) {
                return;
            }

            const data = JSON.parse(JSON.stringify(values));
            delete data[KEY_CREATOR_CODE_EXISTS];
            setIsLoading(true);
            try {
                const res = await Axios.post('v1/my-shop/', data);
                if (res.status < 400) {
                    if (userContext.user) {
                        userContext.setUser(oldUser => {
                            const newUser = Object.assign({}, oldUser);
                            newUser.is_seller = res.data.is_seller;
                            newUser.code = res.data.code;
                            return newUser;
                        });
                    }
                    closeModal();
                    toast.info(t('SellerShopCreateModal.creationSuccess'));
                } else {
                    alert(res.data.display_message || t('SellerShopCreateModal.temporaryError'));
                }
            } catch (e) {
                captureException(e);
                alert(t('SellerShopCreateModal.temporaryError'));
            } finally {
                setIsLoading(false);
            }
        }
    });

    const {errors, values, setFieldValue, touched, setFieldTouched, resetForm, handleSubmit, setTouched, setFieldError} = formik;

    const checkCreatorCode = async (ccode) => {
        if (!ccode) {
            await setFieldValue(KEY_CREATOR_CODE_EXISTS, '');
        }
        try {
            const res = await Axios.get('v1/seller-shop/check-creator-code/', {params: {creator_code: ccode}});
            await setFieldValue(KEY_CREATOR_CODE_EXISTS, res.status === 409 ? t('SellerShopCreateModal.validationMessages.alreadyInUse') : '');
        } catch (e) {
            captureException(e);
            await setFieldValue(KEY_CREATOR_CODE_EXISTS, '');
        }
    }

    const onChangeCreatorCode = (ccode) => {
        if (!ccode) {
            setFieldValue(KEY_CREATOR_CODE, '');
        }
        const lowerCode = ccode.toLowerCase();
        if (lowerCode.match(REGEX_CREATOR_CODE_LEGAL)) {
            setFieldValue(KEY_CREATOR_CODE, lowerCode);
        }
    }

    const ccodeError = !!values[KEY_CREATOR_CODE_EXISTS] || (!!touched[KEY_CREATOR_CODE] && CREATOR_ERROR_FIELDS.filter(field => errors[field]).length > 0);

    const router = useRouter();

    const closeModal = () => {
        router.back();
    }

    const scrollRef = useRef(null);

    useEffect(() => {
        if (router.query.seller_shop_modal) {
            resetForm();
            if (scrollRef.current) {
                scrollRef.current.scrollTo(0, 0);
            }
        }
    }, [router.query.seller_shop_modal]);

    return (
        <Modal isOpen={router.query.seller_shop_modal && userContext.user && !userContext.user.is_seller} close={closeModal} width={'100%'} height={'100%'}>
            <div ref={scrollRef} className={styles.modalScrollContainer}>
                <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                    <TitleBar isClose close={closeModal}>{t('SellerShopCreateModal.title')}</TitleBar>
                </div>
                <div className={classNames(utilStyles.sidePadding, styles.modalContainer)}>
                    <Trans
                      i18nKey="components-cards-MyInfoCard:SellerShopCreateModal.mainTitle"
                      components={[<br />]}
                    />
                    <span className={styles.subTitle}>{t('SellerShopCreateModal.subTitle')}</span>
                    <span className={styles.linkTitle}>{t('SellerShopCreateModal.linkTitle')}</span>
                    <span className={styles.linkSubTitle}>{t('SellerShopCreateModal.linkSubTitle')}</span>
                    <div>
                        <TextInput ellipsis dark readonly={true} value={`https://hemekolab.com/s/${values[KEY_CREATOR_CODE] || ''}`}></TextInput>
                        <div style={{height: 12}} />
                        <TextInput allowEmptyThrottling readonly={isLoading} error={ccodeError} value={values[KEY_CREATOR_CODE]} onBlur={() => setFieldTouched(KEY_CREATOR_CODE)} onChangeThrottled={checkCreatorCode} onChange={onChangeCreatorCode} placeholder={t('SellerShopCreateModal.inputPlaceholder')} maxLength={20} />
                        {
                            (!!values[KEY_CREATOR_CODE] || touched[KEY_CREATOR_CODE]) &&
                            <div style={{marginTop: 6}}>
                                <CheckedInputError isValid={!errors[KEY_CREATOR_CODE_CONTAINS_ALPHABET]} message={t('SellerShopCreateModal.validationMessages.containsAlphabet')} />
                                <CheckedInputError isValid={!errors[KEY_CREATOR_CODE]} message={t('SellerShopCreateModal.validationMessages.lengthLimit')} />
                                {
                                    values[KEY_CREATOR_CODE_EXISTS] && <CheckedInputError isValid={false} message={t('SellerShopCreateModal.validationMessages.alreadyInUse')} />
                                }
                            </div>
                        }
                    </div>
                    <SellerShopInfoForm formik={formik} isLoading={isLoading} />
                    <div style={{height: 67}} />
                    <div className={styles.submitButtonWrapper}>
                        <FullButton disabled={isLoading} height={48} title={t('SellerShopCreateModal.submitButton')} fontSize={16} onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const MyInfoMobileCard = () => {
    const {t} = useTranslation('components-cards-MyInfoCard')
    const user = useContext(UserContext).user;
    if (!user) {
        return <div/>;
    }
    const isSeller = user ? !!user.code && user.is_seller : false;
    const sellerLink = isSeller ? `${SITE_URL}${getCreatorRoute(user.code)}` : '';

    const router = useRouter();
    const openModal = () => {
        router.push({query: Object.assign({}, router.query, {seller_shop_modal: true})}, undefined, {shallow: true});
    }

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const shareModalRef = useRef(null);
    useOutsideAlerter(shareModalRef, () => setIsShareModalOpen(false));

    const reviewCount = user?.writable_reviews?.count || 0;
    const maxReviewReward = user?.writable_reviews?.max_points || 0;

    const onShareLinkClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyInfoCardSellerShopShareLinkClick');
    };

    const onReviewCountClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyInfoCardReviewCountClick');
    };

    const onPointClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyInfoCardPointClick');
    };

    const onCouponClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyInfoCardCouponClick');
    };

    const onProfileImageClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyInfoCardProfileImageClick');
    };

    return (
        <>
            <Script src="https://developers.kakao.com/sdk/js/kakao.js" onLoad={() => {
                Kakao.init(KAKAO_CLIENT_ID);
                Kakao.isInitialized();
            }} strategy="afterInteractive" />
            <div className={styles.mobileContainer}>
                <SellerShopCreateModal />
                <Link href={myInfoRoute}>
                    <a onClick={onProfileImageClick}>
                        <div className={utilStyles.justifyContentCenter}>
                            <ProfileImage user={user} />
                        </div>
                        <div className={styles.nameWrapper}>
                            <span className={styles.name}>{user.nickname}</span>
                            <ArrowRightLargeIcon className={styles.arrow} viewBox="0 0 16 16" />
                        </div>
                    </a>
                </Link>
                <div className={styles.linkContainer} style={{marginTop: !isSeller ? 24: undefined}}>
                    {
                        isSeller && <div className={styles.link}>{sellerLink}</div>
                    }
                    {
                        isSeller ?
                            (
                                navigator.share ?
                                    <div className={styles.linkShareContainer} onClick={() => {
                                        onShareLinkClick();
                                        try {
                                            navigator.share({
                                                title: t('kakaoShare.title', { nickname: user.nickname }),
                                                url: sellerLink,
                                            });
                                        } catch (e) {}
                                    }}>
                                        <ShareIcon className={styles.linkShareIcon} />
                                        <span className={styles.linkShareText}>{t('MyInfoMobileCard.shareLink')}</span>
                                    </div>
                                    :
                                    <>
                                        <div className={styles.linkShareContainer}>
                                            {
                                                isShareModalOpen &&
                                                <div className={styles.shareModal} ref={shareModalRef}>
                                                    <KakaoIcon viewBox="0 0 48 48" className={styles.kakaoIcon} onClick={() => {
                                                        typeof mixpanel !== 'undefined' && mixpanel.track('MyInfoCardSellerShopShareKakaoClick');
                                                        try {
                                                            Kakao.Share.sendScrap({
                                                                 requestUrl: sellerLink,
                                                                templateTitle: t('kakaoShare.title', { nickname: user.nickname }),
                                                            });
                                                        } catch (e) {
                                                            console.log(e)
                                                            Sentry.captureException(e);
                                                        }
                                                    }} />
                                                    <Clipboard
                                                        option-text={() => sellerLink}
                                                        onSuccess={() => {
                                                            typeof mixpanel !== 'undefined' && mixpanel.track('MyInfoCardSellerShopShareClipboardClick');
                                                            toast.info(t('MyInfoMobileCard.linkCopied'));
                                                            setIsShareModalOpen(false);
                                                        }}
                                                    >
                                                        <CopyIcon viewBox="0 0 40 40" className={styles.copyIcon} />
                                                    </Clipboard>
                                                    <CloseIcon viewBox="0 0 20 20" className={styles.closeIcon} onClick={() => {
                                                        typeof mixpanel !== 'undefined' && mixpanel.track('MyInfoCardSellerShopShareCloseClick');
                                                        setIsShareModalOpen(false);
                                                    }} />
                                                </div>
                                            }
                                            <div onClick={() => {
                                                onShareLinkClick();
                                                setIsShareModalOpen(true);
                                            }} className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentCenter)}>
                                                <ShareIcon className={styles.linkShareIcon} />
                                                <span className={styles.linkShareText}>{t('MyInfoMobileCard.shareLink')}</span>
                                            </div>
                                        </div>
                                    </>
                            )
                            :
                            <></>
                    }
                    {
                        isSeller ?
                            <Link href={sellerLink}>
                                <a className={utilStyles.fullWidth} onClick={() => {mixpanel.track('GoToMyShop');}}>
                                    <FullButton title={t('MyInfoMobileCard.myShopButton')} height={40} fontSize={15} />
                                </a>
                            </Link>
                            :
                            true ? <></> :
                            <div className={utilStyles.fullWidth}>
                                {
                                    !user.rrn ?
                                        <SelfVerificationButton
                                            redirect={`${SITE_URL}${myPageRoute}`}
                                            confirmMessage={<span>{t('MyInfoMobileCard.verificationMessage')}</span>}
                                        >
                                            <FullButton title={t('MyInfoMobileCard.createShopButton')} height={40} fontSize={15} />
                                        </SelfVerificationButton>
                                        :
                                        <FullButton title={t('MyInfoMobileCard.createShopButton')} height={40} fontSize={15} onClick={() => {
                                            typeof mixpanel !== 'undefined' && mixpanel.track('CreateSellerShop');
                                            openModal();
                                        }} />
                                }
                            </div>
                    }
                </div>
                {/*<div className={styles.mobileGradeWrapper}>*/}
                {/*    <div>*/}
                {/*        <SilverIcon viewBox="0 0 24 24" className={styles.gradeIcon}/>*/}
                {/*        <span className={styles.gradeName}>SILVER</span>*/}
                {/*    </div>*/}
                {/*    <span className={styles.gradeRewardWrapper}><a className={styles.gradeReward}><span*/}
                {/*        className={styles.gradeRewardText}>등급별 혜택</span></a></span>*/}
                {/*</div>*/}

                <div className={styles.mobileRewardContainer} style={{marginTop: 12}}>
                    <Link href={reviewRoute}>
                        <a className={styles.rewardWrapper} onClick={onReviewCountClick}>
                            <span className={styles.rewardKey}>{t('MyInfoMobileCard.rewards.review')}</span>
                            <span className={styles.rewardValue}>{numberWithComma(reviewCount)}</span>
                            {
                                maxReviewReward > 0 &&
                                <div className={styles.reviewBubbleWrapper}>
                                    <SpeechBubble
                                        sidePadding={10} verticalPadding={6}
                                        pointLength={5}
                                        top={true}
                                        bottom={false}
                                        backgroundColor={'#191919'}
                                    >
                                        <div className={styles.reviewBubbleContent}>
                                            {t('MyInfoMobileCard.rewards.pointsReward', { amount: numberWithComma(maxReviewReward) })}
                                        </div>
                                    </SpeechBubble>
                                </div>
                            }
                        </a>
                    </Link>
                    <Link href={pointsRoute}>
                        <a className={styles.rewardWrapper} onClick={onPointClick}>
                            <span className={styles.rewardKey}>{t('MyInfoMobileCard.rewards.points')}</span>
                            <span className={styles.rewardValue}>{numberWithComma(user.point)}</span>
                        </a>
                    </Link>
                    {/*<div className={styles.verticalBorder}/>*/}
                    <Link href={couponsRoute}>
                        <a className={styles.rewardWrapper} onClick={onCouponClick}>
                            <span className={styles.rewardKey}>{t('MyInfoMobileCard.rewards.coupons')}</span>
                            <span className={styles.rewardValue}>{user.coupon_count}</span>
                        </a>
                    </Link>
                </div>
            </div>
        </>

    )
}


export function MyInfoCard() {
    return (
        <MyInfoMobileCard />
    )
}
